<template>
    <NuxtLink
        class="mx-3 px-3 my-2 h-auto py-3 flex flex-row align-middle items-center hover:bg-gray-700 rounded-md cursor-pointer transition-all duration-200"
        :to="link"
        :class="{
            'bg-gray-700 shadow-lg': isActive,
            'bg-transparent': !isActive,
        }">
        <font-awesome-icon :icon="icon" class="text-white text-xl w-5" />
        <span class="ml-5 text-white">
            {{ title }}
        </span>
    </NuxtLink>
</template>
<script>
export default defineNuxtComponent({
    props: {
        title: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            required: true,
        },
    },
    data() {
        const isActive = this.$route.path.startsWith(this.link);
        return {
            isActive,
        };
    },
    updated() {
        this.isActive = this.$route.path.startsWith(this.link);
    },
    watch: {
        $route() {
            this.isActive = this.$route.path.startsWith(this.link);
        },
    },
    methods: {
        route() {
            this.$router.push(this.link);
        },
    },
});
</script>
